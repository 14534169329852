import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import EmailCapture from "../components/emailCatpure"
// import EmailRedirect from "../components/emailFormRedirect"

const FeaturesPage = () => (
  <Layout>
    <SEO title="Features" />
    {/* HERO */}
    <div className="hero1">
      <div className="content taCenter">
        <h1>Features</h1>
        <p>
          Check out everything Vantage can do for you{" "}
          <span role="img" aria-label="raised-hands">
            🙌
          </span>
        </p>
      </div>
    </div>

    {/* HOW IT HELPS */}
    <div className="hero3">
      <div className="content">
        {/* <h1>How Vantage Helps</h1> */}
        <div className="flexContainer">
          <div className="card">
            <p>
              <i className="material-icons">question_answer</i>
            </p>
            <h2>2 Way Chat</h2>
            <p>
              Easily reply to customer inquiries when they respond to a message.
            </p>
          </div>
          <div className="card">
            <p>
              <i className="material-icons">date_range</i>
            </p>
            <h2>Scheduled Send</h2>
            <p>
              Set it and forget it when creating your promotion using our
              schedule feature.
            </p>
          </div>
          <div className="card">
            <p>
              <i className="material-icons">cloud_upload</i>
            </p>
            <h2>Bulk Import</h2>
            <p>
              Quickly and easily upload your contacts from a csv file, or send
              them to us and we can do it for you!
            </p>
          </div>
          <div className="card">
            <p>
              <i className="material-icons">security</i>
            </p>
            <h2>Automated Opt Outs</h2>
            <p>
              We automatically remove customers who opt out so you don't have
              to.
            </p>
          </div>
          <div className="card">
            <p>
              <i className="material-icons">group</i>
            </p>
            <h2>Multiple Agents</h2>
            <p>
              Invite your coworkers or business partners to your organization to
              help manage customer replies and schedule promotions.
            </p>
          </div>
          <div className="card">
            <p>
              <i className="material-icons">photo</i>
            </p>
            <h2>Picture Messaging (MMS)</h2>
            <p>
              They say a picture is worth 1000 words. Use them to supercharge
              your promotion using branded images.
            </p>
          </div>
          <div className="card">
            <p>
              <i className="material-icons">map</i>
            </p>
            <h2>Works Everywhere</h2>
            <p>
              VantageSMS runs in the cloud so you can use it anywhere you have
              internet access, even at the beach.{" "}
              <span role="img" aria-label="beach">
                ⛱️
              </span>
            </p>
          </div>

          <div className="card" />
          {/* placeholder card used to shift odd number of cards into column format */}
        </div>
      </div>
    </div>

    {/* HERO FOOTER*/}
    <div className="heroFooter taCenter">
      <div className="content">
        <h1>Grow Your Sales</h1>
        {/* <p>
          Share your contact lists. Collaborate on message. We take care of the
          rest!
        </p> */}
        <p>Reach the customers that matter most, your own.</p>
        <EmailCapture />
        {/* <p style={{ padding: "0px 20px" }}>
          <input type="email" name="" placeholder="your.name@email.com" />
          <br />
          <button className="">Get Started</button>
          <button className="">Yes, Contact Me</button>
          <button className="">Yes, Contact Me to Get Started</button>
        </p> */}
      </div>
    </div>
  </Layout>
)

export default FeaturesPage
